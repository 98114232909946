/* eslint-disable max-len,react/no-danger */
import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import { PageHeader, Seo, Footer } from "components/common";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import styles from "components/commonStyles";
import { siteUrl } from "configs/constants";
import Img from "gatsby-image";

function Java({ classes, data }) {
  const bgImage = get(data, "image.edges[0].node.childImageSharp.fluid", {});

  return (
    <>
      <Seo title="Java" url={`${siteUrl}/java`} />
      <PageHeader />
      <div className={classes.content}>
        <p style={{ marginBottom: 60 }}>
          Server has native java classes delivered as JAR file, javadoc,
          examples on java language. Before reading we recommend you to read
          Terms, Security and Error handling.
        </p>
        <h4>A reference</h4>
        <p style={{ marginBottom: 10 }}>At MAVEN SITE you can find:</p>
        <ul style={{ marginBottom: 60 }}>
          <li>apidocs</li>
          <li>source codes</li>
          <li>
            instructions for including the library to your projects at
            Dependency and Repositories page for various types of build engines
          </li>
          <li>
            you can also manually download the JAR files (after getting
            username/password from support team) from
            https://dev.srv4pos.com/nexus/content/repositories/releases/com/srv4pos/server/server-api/
          </li>
        </ul>
        <h4>Samples and test runs</h4>
        <p style={{ marginBottom: 10 }}>
          In addition to API classes, server-api.jar contains specail tool
          called Diagnostics:
        </p>
        <ol style={{ marginBottom: 60 }}>
          <li>
            This is central place to find all the samples: it calls classes such
            as TaxServiceSample, ProductServiceSample etc.
          </li>
          <li>
            You can use it to verify is your environment properly configured to
            be able to talk to the server by running all those sample one by
            one. Just{" "}
            <span className={classes.textHead}>
              {
                "run java -jar server-api-{version}-jar-with-dependencies.jar http://servername.com true"
              }
            </span>
          </li>
          <li>
            You also can run Diagnostics from your java app for testing purpose.
            Do it each time you think there is a bug on the server before
            sending bugreport to srv4pos support team <span role="img">😉</span>
          </li>
        </ol>
        <h4>Where to start?</h4>
        <p style={{ marginBottom: 10 }}>
          There is a lot of classes in the api. Is there any place where I can
          start from?
        </p>
        <ul style={{ marginBottom: 60 }}>
          <li>
            Use Diagnostics sources to copy it’s source code into your app.
          </li>
          <li>
            Use ServiceFactory (src) to get access to all API. It’s a signle
            entry point to the API. It is lightweight. Which means you can
            create it pretty often by demand.
          </li>
          <li>
            Carefully read further <span role="img">🙂</span>
          </li>
        </ul>
        <h4>Naming conventions</h4>
        <ul style={{ marginBottom: 60 }}>
          <li>
            <span className={classes.textHead}>*Service</span> classes provide
            the functionality. They are also lightweight.
          </li>
          <li>
            <span className={classes.textHead}>*ServiceSample</span> classes
            provides corresponding samples.
          </li>
          <li>
            <span className={classes.textHead}>*Info classes</span> are{" "}
            <a href="https://martinfowler.com/eaaCatalog/dataTransferObject.html">
              <span className={classes.textColor}>Data Transfer Objects</span>
            </a>
            , they are basic{" "}
            <a href="https://en.wikipedia.org/wiki/Plain_old_Java_object">
              <span className={classes.textColor}>POJO</span>
            </a>{" "}
            – a data structures.
          </li>
          <li>
            <span className={classes.textHead}>*AdvancedInfo</span> class is an
            extention for a particular *Info class. For instance CommodityInfo
            might have own extention as CategoryAdvancedInfo. But it’s
            restricted to have FooAdvancedInfo without having FooInfo. The
            motivation for having (for example) BarAdvancedInfo if BarInfo have
            field “String foo” which is basically a string identifier to Foo
            entity. But sometimes we need to get a full structre at one call. So
            we want to have “Foo foo” instead of “String foo”. Assuming we need
            both we have BarAdvancedInfo which contains “Foo foo” and BarInfo
            which contains “String foo”. Compare CommodityInfo and{" "}
            CategoryAdvancedInfo.
          </li>
          <li>
            <span className={classes.textHead}>*ReplyInfo</span> are dummy
            classes for few methods which returns a single value. By convention
            we have to return a structure, not a single value. Therefore we have
            to have those kind of classes.
          </li>
          <li>
            <span className={classes.textHead}>*JsonException</span> is an
            exception thrown by server. It’s explained bellow and at Error
            handling.
          </li>
          <li>
            <span className={classes.textHead}>*Helper</span> classes also
            provide the functionality similar to Services but do not work with a
            remote.
          </li>
          <li>
            <span className={classes.textHead}>*Utils</span> classes have the
            same semantic as many similar classes from apache-commons.
          </li>
        </ul>
        <h4>Few more tips</h4>
        <ul style={{ marginBottom: 60 }}>
          <li>
            Server time is UTC. When it’s needed to pass some date, round time
            to 0:00:00:000 in UTC. It’s some sort of validation that you use
            right timezone.
          </li>
          <li>
            There is a very usefull class Registry. Use it! See example of it’s
            usage in Diagnostics, method main. By using it you have a guarantee
            server version matches to your version. If it’s not, server
            gracefully throw OutdatedVersionJsonException instead of breaking
            your logic. Also you can get the actual URL of cloud servers with
            the method Registry.getCloudServerUrl(boolean development) .
          </li>
          <li>
            If you don’t know what to use as Transport passed to ServiceFactory,
            use HttpUrlTransport.
          </li>
          <li>
            Curious which Credentials to use?
            <ul style={{ marginTop: 10 }}>
              <li>
                Use ActivationCredentials if you are not human. E.g. when you{" "}
                develop an integration module for your software . When you want
                to send data to Control Unit. When you send receipts to the
                server from your POS terminal.
              </li>
              <li>
                Use SellerUserCredentials if you have a user profile on the
                server (username, password).
              </li>
              <li>
                Use SellerCredentials if you don’t have any particular account.
                In this case only the public data will be available.
              </li>
            </ul>
          </li>
          <li>
            In case of ActivationCredentials is used, the server requests seller
            version when you create, edit or delete information. Don’t forget to
            provide it. Read{" "}
            <Link to="/developers/sync/">
              <span className={classes.textColor}>
                Synchronization and Caching
              </span>
            </Link>{" "}
            for more info.
          </li>
          <li>
            Remember, that if you have an ability to keep some data in your
            local storage (e.g.{" "}
            <a href="http://memcached.org/">
              <span className={classes.textColor}>memcached</span>
            </a>
            ) or in memory (
            <a href="https://docs.oracle.com/javase/7/docs/api/java/util/HashMap.html">
              <span className={classes.textColor}>HashMap</span>
            </a>{" "}
            ?), do that to increase performance! Save sellerVersion together
            with the data and provide it to methods which return the data. If
            those methods return null instead of empty array – it means server
            don’t have any new (updated) data so you can reuse the data from
            your local cache.
          </li>
        </ul>
        <h4>Date & time</h4>
        <p style={{ marginBottom: 60 }}>All data & time is always in UTC.</p>
        <h4>Activation module</h4>
        <Img fluid={bgImage} style={{ marginBottom: 30 }} />
        <p style={{ marginBottom: 60 }}>Class diagram</p>
        <h4>Proguard/DEX config</h4>
        <ul>
          <li>
            <p style={{ marginBottom: 20 }}>
              {
                "# server-api -dontwarn javax.validation.* -dontwarn javax.validation.constraints.* -dontwarn org.aspectj.lang.* -dontwarn org.aspectj.lang.annotation.* -dontwarn org.aspectj.internal.lang.annotation.* -keep class com.pos4mobile.server.api.** { *; } -keeppackagenames com.srv4pos.server.api.activation.** -keep class flexjson.** {*;} -keepclassmembers class flexjson.** {*;}"
              }
            </p>
            <p>
              {
                "1 2 3 4 5 6 7 8 9 10 11 12 13 # server-api -dontwarn javax.validation.* -dontwarn javax.validation.constraints.* -dontwarn org.aspectj.lang.* -dontwarn org.aspectj.lang.annotation.* -dontwarn org.aspectj.internal.lang.annotation.* -keep class com.pos4mobile.server.api.** { *; } -keeppackagenames com.srv4pos.server.api.activation.** -keep class flexjson.** {*;} -keepclassmembers class flexjson.** {*;}"
              }
            </p>
          </li>
        </ul>
      </div>
      <Footer />
    </>
  );
}

export const query = graphql`
  query {
    image: allFile(filter: { relativePath: { eq: "other/java.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(srcSetBreakpoints: [200, 340, 520, 800, 890], quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

Java.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textHead: PropTypes.string.isRequired
  }).isRequired,
  data: PropTypes.shape({}).isRequired
};

export default withStyles(styles, { withTheme: true })(Java);
